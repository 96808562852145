import React from "react";
import { Outlet } from "react-router-dom";

const Calculators = () => {
  return (
    <Outlet/>
  );
};

export default Calculators;
